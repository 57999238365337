import { render, staticRenderFns } from "./ViolationInfo.vue?vue&type=template&id=dd2b3664&scoped=true&"
import script from "./ViolationInfo.vue?vue&type=script&lang=js&"
export * from "./ViolationInfo.vue?vue&type=script&lang=js&"
import style0 from "./ViolationInfo.vue?vue&type=style&index=0&id=dd2b3664&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd2b3664",
  null
  
)

export default component.exports